import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logger from 'utils/logger';
import { Link } from 'gatsby';
import Img from 'components/Elements/Img';

export default class CardProductSeries extends React.Component {
  render () {
    // logger.component(__filename, '>', 'render()');
    const productSeries = this.props;
    // logger.info('productSeries', productSeries);
    const propClasses = productSeries.className;
    const classes = classNames(
      'card-product',
      'product',
      ...propClasses
    );
    // function cleanArray (actual) {
    //   const newArray = [];
    //   for (let i = 0; i < actual.length; i += 1) {
    //     if (actual[i]) {
    //       newArray.push(actual[i]);
    //     }
    //   }
    //   return newArray;
    // }
    // const productsASC = cleanArray(productSeries.products);

    // logger.debug('productSeries', productSeries);

    const products = productSeries.products || [];
    // logger.componentEnd();
    return (
      <article
        className={classes}
        itemScope
        itemType="http://schema.org/Product"
      >
        <div className="card-content">
          <Link
            to={`/support/resources/${productSeries.productLineSlug}/${
              productSeries.slug
            }`}
            itemProp="url"
            title={`${productSeries.model} Resources`}
          >
            <div className="thumbnail mb-15em">
              <Img
                title={`${productSeries.productLine} ${productSeries.name}`}
                alt={`${productSeries.productLine} ${productSeries.name}`}
                sizes={productSeries.thumbnail.fluid}
                backgroundColor={`#fafafa`}
                style={{
                  position: `absolute`,
                  top: `0`,
                  left: `0`,
                  height: `100%`,
                  width: `100%`,
                }}
                imgStyle={{
                  top: `50%`,
                  transform: `translate3D(-50%,-50%,0)`,
                  left: `50%`,
                }}
                objFit={`contain`}
                fadeIn
              />
            </div>
            <h1 itemProp="name" className={`lh-12 pb-10em mb-0`}>
              <span
                className={`fw-600 alpha-60`}
                style={{ fontSize: `13px`, letterSpacing: `0px` }}
              >
                {productSeries.productLine}
              </span>
              <br />
              <span>
                {productSeries.name}
                {/*
                {productSeries.productLine}
                <span className="fw-400">{` ${productSeries.name}`}</span>
                */}
              </span>
            </h1>
          </Link>
          {/* 
          <div className="description" itemProp="description">
            <p className="lead">{productSeries.tagline}</p>

            {productSeries.summary && (
              <p>{productSeries && productSeries.summary && productSeries.summary.summary}</p>
            )}
          </div>
             */}
          <div style={{ borderTop: '1px solid #92989e', paddingTop: "1em" }}>
            {products && products.length > 0 && (
              <ul className="models fs-08em uls-none prl-0 mrl-0 lh-14 pb-20em">
                <li>
                  <b>Available Models</b>
                </li>
                {products.map((product, i) => {
                  // logger.debug(`product ${product.name}`, product);
                  return (
                    product &&
                    product.name &&
                    !product.eol && (
                      <li
                        key={`product-${product.id}`}
                        className={`model ${
                          product.name
                        } mb-0 ${products.length > 0 &&
                          `inline-block mr-0 text-center`}`}
                        style={{
                          minWidth: `25%`,
                          padding: `2px 4px 2px 0`,
                        }}
                      >
                        {/*
                            <span>
                              <b>{product.name}</b>
                              {products.length < 5 ? (
                                product.tagline && `: ${product.tagline}`
                              ) : (
                                i === products.length - 1 ? `.` : `, `
                              )}
                            </span>
                          */}
                        <div
                          className={`bg-gray-warm-200`}
                          style={{ display: `block`, padding: `2px 4px` }}
                        >
                          <b>{product.name}</b>
                          {/*
                            { i === products.length - 1 ? `` : <span className={`alpha-50 mrl-5em`}>|</span> }
                            */}
                        </div>
                      </li>
                    )
                  )
                })}
              </ul>
            )}
          </div>
        </div>
      </article>
    )
  }
}
CardProductSeries.propTypes = {
  thumbnail: PropTypes.shape({
    resolutions: PropTypes.shape({
      src: PropTypes.string.isRequired
    })
  }),
  name: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    summary: PropTypes.string.isRequired,
  }),
  slug: PropTypes.string.isRequired,
  // keyOf: PropTypes.string.isRequired,
  // models: PropTypes.arrayOf(String),
  className: PropTypes.string
};
CardProductSeries.defaultProps = {
  // models: [],
  className: ''
};
