import React, { Component } from 'react';
// import NavigationItem from './NavigationItem';
import Search from 'components/Search/Search';
import logger from 'utils/logger';
import { Link } from 'gatsby';
import { Row, Col } from 'reactstrap';
import LoadingView from 'components/Loaders/LoadingView';

import CardProductSeriesResources from 'components/Cards/CardProductSeriesResources';
import Timer from 'components/Loaders/Timer';
import numeral from 'numeral';
import matchSorter from 'match-sorter';

class VerticalNavigationList extends Component {
  constructor (...rest) {
    super(...rest);
    this.state = {
      hits: null,
      query: null,
      fetchingResults: true
    };
  }
  render () {
    const { data } = this.props;

    logger.gql(`data`, data);
    if (
      !data
    ) { return <LoadingView />; }

    const downloads = data.allContentfulDownloads.edges;
    const productSeries = data.allContentfulProductSeries.edges;
    const searchData = data.siteSearchIndex;

    const { hits, query } = this.state;
    // logger.verbose(`edges`, edges);
    logger.verbose(`query`, query);
    logger.verbose(`hits`, hits);

    const hitsFilter = matchSorter(hits, query, {
      keys: [
        'type',
        'title',
        'name',
        'categories',
        'content',
        'productLine',
        'productGroup',
        'products',
        'keywords'
      ],
      threshold: matchSorter.rankings.EQUALS
    });
    // logger.verbose(`hitsFilter`, hitsFilter);

    const resultsResources = downloads.filter(({ node }) =>
      !hitsFilter || hitsFilter.filter(hit => hit.id === node.id).length > 0);
    const resultsSeries = productSeries.filter(({ node }) =>
      !hitsFilter || hitsFilter.filter(hit => hit.id === node.id).length > 0);

    // logger.verbose(`resultsResources`, resultsResources);
    // logger.verbose(`resultsSeries`, resultsSeries);

    return (
      <div>
        <Search
          data={searchData}
          label={'Search'}
          className={`form-control mui mui-search mui-search-xl ptb-20px resourceSearch`}
          placeholder={this.props.placeholder}
          onSearch={(text, hits) =>
            this.setState({
              hits: text !== '' ? hits : null,
              query: text !== '' ? text : null
            })
          }
        />
        <div>
          {!hits ? (
            <div>{' '}</div>
          ) : (
            <div className="resultsContainer">
              {hits.length === 0 ? (
                <div>
                  <Timer>No results found.</Timer>
                </div>
              ) : (
                <div>
                  {resultsSeries.length > 0 && (
                    <div className={`ptb-20em`}>
                      <Row>
                        {resultsSeries.map((edge) => {
                          const { node } = edge;
                          // logger.debug(`node`, node.name, node);
                          const cardProps = {
                            ...node,
                            productLine:
                              node.productLine && node.productLine.name,
                            productGroup:
                              node.productGroup && node.productGroup.name,
                            productLineSlug:
                              node.productLine && node.productLine.slug,
                            productGroupSlug:
                              node.productGroup && node.productGroup.slug
                          };
                          return (
                            <Col
                              key={`node-productSeries-${node.id}`}
                              xl={2}
                              md={3}
                              sm={4}
                            >
                              <CardProductSeriesResources {...cardProps} />
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                  {resultsResources.length > 0 && (
                    <div className={`ptb-20em`}>
                      <Row>
                        {resultsResources.map((edge) => {
                          const { node } = edge;
                          const download = node;
                          if (download.resource) {
                            // logger.debug(`node`, node.name, node);
                            let downloadType = download.type;
                            if (downloadType === `document`) {
                              downloadType = `support`;
                            }
                            let icon;
                            if (downloadType === `datasheet`) {
                              icon = `File-Chart`;
                            } else if (
                              downloadType === `manual` ||
                              downloadType === `support`
                            ) {
                              icon = `Book`;
                            } else if (downloadType === `catalog`) {
                              icon = `Address-Book2`;
                            } else {
                              icon = `Download-fromCloud`;
                            }
                            let extension = download.resource.file.contentType.split(`/`).pop();
                            if (extension === `x-dosexec`) { extension = `Executable`; }
                            if (extension === `octet-stream`) { extension = `Executable`; }
                            return (
                              <Col lg={6} xl={4} key={`download-${download.id}`} className={`mb-10em`}>
                                <a
                                  href={download.resource.file.url}
                                  alt={download.resource.file.fileName}
                                  target={`_blank`}
                                  className={`color-gray-cold-700 color-primary-600-hover`}
                                >
                                  <div>
                                    <span className={`inline-block prl-0 pos-relative`} style={{ top: `-5px` }}>
                                      <i className={`icon icon-${icon} fs-15em mr-5em color-primary-500`} />
                                    </span>
                                    <span className={`inline-block pl-10em fs-09em`}>
                                      <span><b>{download.name}</b></span>
                                      <br />
                                      <span className={`alpha-70 fs-08em tt-uppercase`}>{extension} | {numeral(download.resource.file.details.size).format('0.0 b')}</span>
                                    </span>
                                  </div>
                                </a>
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default VerticalNavigationList;
