import React from 'react';
import { Treebeard } from 'react-treebeard'
import Layout from 'components/Layout';
import { /*StaticQuery, */graphql, Link } from 'gatsby';
import _ from 'lodash';
import get from 'lodash/get';
import SEO from 'components/SEO/SEO';
import logger from 'utils/logger';
import { Container, Row, Col } from 'reactstrap';
import { Fluid } from 'components/Containers';

import LoadingView from 'components/Loaders/LoadingView';
import ResourcesHeader from 'components/Headers/ResourcesHeader'
import SearchResources from 'components/Search/SearchResources'
import MenuTree from 'components/Search/MenuTree'
import treeDataSchema from '../components/Search/ResourcesTreeData';

class Resources extends React.Component {
  constructor(props) {
    super(props)
    this.state = {  }
    this.onToggle = this.onToggle.bind(this)
  }

  onToggle(node, toggled) {
    const { cursor, data } = this.state
    if (cursor) {
      this.setState(() => ({ cursor, active: false }))
    }
    node.active = true
    if (node.children) {
      node.toggled = toggled
    }
    this.setState(() => ({ cursor: node, data: Object.assign({}, data) }))
  }
  render() {
    logger.template(__filename, '>', 'render()')
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const data = get(this, 'props.data')
    logger.gql(data)
    if (
      !data ||
      !data.allContentfulProductLine ||
      !data.allContentfulDownloads
    ) {
      return <LoadingView />
    }
    const productLinesData = data.allContentfulProductLine.edges;

    logger.verbose('productLinesData', productLinesData)

    const treeData = treeDataSchema(productLinesData)

    // const treeData = treeProductLines;
    logger.verbose('treeData', treeData)

    logger.templateEnd()
    return (
      <Layout>
        <Fluid>
          <SEO title={`Resources`} pathname={`/support/resources`} />
          <ResourcesHeader />
          <section className={`articles bt-1`}>
            <Container fluid>
              <Row>
                <Col
                  md={{ size: 12, order: 2 }}
                  lg={{ size: 8, order: 2 }}
                  className={`ptb-30em prl-30em`}
                >
                  <article>
                    <header className={`pt-20em lh-12 pb-15em`}>
                      <h1 className={`ff-base fs-25em fw-700`}>
                        Downloads & Resources
                      </h1>
                      <p>
                        Find software, data sheets, guides, catalogs, and
                        other resources for your Apantac devices.
                      </p>
                    </header>
                    <main>
                      <SearchResources
                        currentSlug={'/'}
                        data={data}
                        placeholder="Ex. T# 4 RU"
                      />
                    </main>
                  </article>
                </Col>
                <Col
                  lg={3}
                  xl={3}
                  className={`bg-gray-cold-100 sidebar prl-0 d-none d-lg-block`}
                  style={{ borderRight: `1px solid #f1f0f7` }}
                >
                  <aside className={`sidebar`}>
                    <div>
                      <MenuTree defaultData={treeData} />
                    </div>
                  </aside>
                </Col>
              </Row>
            </Container>
          </section>
        </Fluid>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allContentfulProductLine(sort: { fields: [sort], order: DESC }) {
      edges {
        node {
          id
          ...ProductLineBase
          product_group {
            ...ProductGroupBase
            categories {
              ...Category
            }
            series {
              name
              eol
              productLine {
                name
                slug
              }
              fields {
                categories
              }
              ...ProductSeriesBase
              thumbnail {
                ...Thumbnail
              }
              products {
                id
                name
              }
            }
          }
        }
      }
    }
    siteSearchIndex {
      index
    }
    allContentfulProductSeries {
      edges{
        node {
          id
          ... ProductSeriesBase
          internal {
            type
          }
          productLine {
            name
            slug
          }
          thumbnail {
            ... Thumbnail
          }
          products {
            id
            name
          }
        }
      }
    }
    allContentfulDownloads{
      edges{
        node {
          id
          ... Download
        }
      }
    }
  }
`

export default Resources;
